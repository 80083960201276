<template>
    <b-modal size="lg" v-model="computedShow" no-close-on-backdrop :title="trans('settings-edit-translation',167)">
        <ValidationObserver ref="form" >

            <app-row-left-label class="mt-2" :label="trans('settings-key-name',167)">
                <validation-provider v-slot="{ errors }" vid="key_name"   rules="required" name="Sleutelnaam" > 
                    <app-input v-model="cArgs.key_name" :disabled="true"/>
                    <span id="error" class=" d-flex justify-content-start ml-1 text-danger app-input-error-ellips">{{ errors[0] }}</span>   
                </validation-provider> 
            </app-row-left-label>


        
            <b-tabs ref="tabs" class="mb-3 mt-3" justified >
                <b-tab title="NL">
                    <app-memo v-model="cArgs.value_nl" rows="7" :maxLength="100000"/>
                </b-tab>  
                <b-tab title="EN">
                        <app-memo v-model="cArgs.value_en" rows="7" :maxLength="100000" />
                </b-tab>
            </b-tabs>


            <app-row-left-label  class="mt-2" :label="trans('description',182)">
                <app-memo v-model="cArgs.description" rows="4" :maxLength="100000" />
            </app-row-left-label>

            <app-row-left-label  class="mt-2" :label="trans('settings-group',167)">
                <app-input v-model="cArgs.category_name" :disabled="true"/>
            </app-row-left-label>

             
        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="editTranslation" type="primary" :loading="loading">
                 {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return"],

    watch: {
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         }
    },

    data(){
        return {
            loading: false,
            opt: [ 
                { value: 1, text: this.trans('settings-internal',167) }, 
                { value: 2, text: this.trans('settings-external',167) },
                { value: 3, text: this.trans('portal',182) },
            ],
        }
    },  

    methods: {


        async editTranslation(){
                this.loading = true;

                this.$refs.form.validate().then((result) => {
                    
                    if (!result) {       
                        this.loading = false;    
                        return false;
                    }

                    axios
                        .post('settings/translations/change', {
                            id_content_data_main: this.cArgs.id_content_data_main,
                            key_name: this.cArgs.key_name, 
                            value_nl: this.cArgs.value_nl, 
                            value_en: this.cArgs.value_en, 
                            description: this.cArgs.description, 
                            id_content_data_group: this.cArgs.category_id, 
                            id_content_const_application: this.cArgs.id_content_const_application,
                            filters: JSON.stringify(this.cArgs.filters),
                        })
                        .then((res) => {
                            this.cReturn = res.data.list.items;
                            this.translations.changeDictionary(res.data.dictionary);
                            this.$emit('update:show', false) 
                        })
                        .catch((error) => {
                            console.error(`error during request ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;   
                        })
                });
        },

        closeModal(){
            this.computedShow = false;
        },
    }

    
}
</script>
<style scoped>

</style>