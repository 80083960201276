<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-dictionary-list',207)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :isFilterOptions="true" :filterOptions="list">
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="editName(cell.data.item)">{{trans('change',175)}}</b-dropdown-item>
                </template>
            </app-view-table>
        </b-card>  

        <setting-translations-database-edit v-if="mNameEdit.show" :show.sync="mNameEdit.show" :result.sync="mNameEdit.result" :args="mNameEdit.args" :filters.sync="filters" :return.sync="items"/>
    </div>
</template>

<script>
import axios from "@axios";
import SettingTranslationsDatabaseEdit from "./SettingTranslationsDatabaseEdit.vue";

export default {
    components: {
        SettingTranslationsDatabaseEdit,
    },

     mounted() {
            this.onCreateThis();
    },
    
    data() {
        return {
            mNameEdit: { show: false, args: { id: null, name: null, table: null, title: null, message: null, changed_column: null}, result: 0, return: null },
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 10,
                list_type: 1,
                changed: 0,
                table_name: 's_person.t_action_const_type',
            },
            fields: [
                { key: "id", label: this.trans('settings-id',207), thStyle: { "min-width": "70px", "max-width": "70px" }, visible: true},
                { key: "name", label: this.trans('full-name',3), thStyle: { width: "100%" }, visible: true},      
                { key: "title", label: this.trans('td-title',205), thStyle: { "min-width": "400px", "max-width": "400px" }, visible: false},  
                { key: "message", label: this.trans('settings-message',207), thStyle: { width: "100%" }, visible: false},  
            ],
            list: [
                { value: 1, name: this.trans('person-action-types',260), table_name: 's_person.t_action_const_type' },
                { value: 2, name: this.trans('person-availability',260), table_name: 's_person.t_availability_const_type' },
                { value: 3, name: this.trans('person-competence-groups',260), table_name: 's_person.t_competence_const_group' },
                { value: 4, name: this.trans('person-competences',260), table_name: 's_person.t_competence_const_type' },
                { value: 5, name: this.trans('person-compliances',260), table_name: 's_person.t_compliance_const_type' },
                { value: 6, name: this.trans('person-function-sections',260), table_name: 's_person.t_function_const_section' },
                { value: 7, name: this.trans('person-function-types',260), table_name: 's_person.t_function_const_type' },
                { value: 8, name: this.trans('person-martial-types',260), table_name: 's_person.t_martial_const_type' },
                { value: 9, name: this.trans('person-statuses',260), table_name: 's_person.t_status_const_type' },
                { value: 10, name: this.trans('notification-types', 207), table_name: 's_person.t_notification_const_type' },
                { value: -1, name: ""},
                { value: 11, name: this.trans('client-contract-statuses',260), table_name: 's_client.t_contract_const_status' },
                { value: 12, name: this.trans('client-invoice-frequency',260), table_name: 's_client.t_frequency_const_type' },
                { value: 13, name: this.trans('client-statuses',260), table_name: 's_client.t_status_const_type' },
                { value: -2, name: ""},
                { value: 14, name: this.trans('placements-components',260), table_name: 's_account.t_placement_const_component' },
                { value: 15, name: this.trans('placements-statuses',260), table_name: 's_account.t_placement_const_status' },
                { value: 16, name: this.trans('target-group',260), table_name: 's_account.t_group_const_type' },
                { value: 17, name: this.trans('sectors',260), table_name: 's_account.t_sector_const_type' },
                { value: -3, name: ""},
                { value: 18, name: this.trans('applicant-statuses',260), table_name: 's_plan.t_applicant_const_status' },
                { value: 19, name: this.trans('assignment-statuses',260), table_name: 's_plan.t_assignment_const_status' },
                { value: 20, name: this.trans('shift-daytimes',260), table_name: 's_plan.t_shift_const_daytime' },
                { value: 21, name: this.trans('shift-statuses',260), table_name: 's_plan.t_shift_const_status' },
                { value: -4, name: ""},
                { value: 22, name: this.trans('token-statuses',260), table_name: 's_app.t_token_const_status' },
                { value: 23, name: this.trans('document-types',260), table_name: 's_sys.t_document_const_type' },
                { value: 24, name: this.trans('s-types-of-actions',207), table_name: 's_sys.t_issue_const_type' },

            ],
            items: [],
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("settings/translations/getDatabaseList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        editName: function(data) {
            this.mNameEdit.args.name = data.name;
            this.mNameEdit.args.id = data.id;
            this.mNameEdit.args.changed_column = 'name';
            if(this.filters.list_type == 10){
                this.mNameEdit.args.title = data.title;
                this.mNameEdit.args.message = data.message;
            }else if(this.filters.list_type == 24){
                this.mNameEdit.args.changed_column = 'title';
            }
            this.mNameEdit.args.table = this.filters.table_name;
            this.mNameEdit.show = true;
        },

        onCreateThis() { 

            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    if(this.filters.list_type == 10){
                        this.fields[1].visible = false;
                        this.fields[2].visible = true;
                        this.fields[3].visible = true;
                    }else{
                        this.fields[1].visible = true;
                        this.fields[2].visible = false;
                        this.fields[3].visible = false;
                    }
                    this.filters.table_name = this.list.find(x => x.value == this.filters.list_type).table_name;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
};
</script>

<style scoped></style>
