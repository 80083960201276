<template>
    <b-modal size="md" v-model="computedShow" no-close-on-backdrop :title="trans('settings-add-group',167)">
        <ValidationObserver ref="form" >

           <app-row-left-label :label="trans('settings-group-name',207)">
                 <app-input v-model="category_name" validatorId="category_name" validatorName="Groep naam" validatorRules="required" mask="lettersE5-40"/>
            </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="addTranslation" type="primary" :loading="loading">
                 {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return", "title"],

    watch: {
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         },

         cTitle: {
             get() { return this.title },
             set(value) { this.$emit('update:title', value) }
         }
    },

    data(){
        return {
            loading: false,
            category_name: null
        }
    },  

    methods: {


        async addTranslation(){
                this.loading = true;

                
                this.$refs.form.validate().then((result) => {
                    if(this.cArgs.type == 'sub'){
                        if(this.cArgs.elements.some(item => item.name.toUpperCase() == this.category_name.toUpperCase() && 
                                                    item.id_parent == this.cArgs.item.id)){
                            
                            this.$refs.form.setErrors({"category_name":[this.trans('settings-category-exist',208)]});
                            result = false;
                        }
                    }
                    else{
                        if(this.cArgs.elements.some(item => item.name.toUpperCase() == this.category_name.toUpperCase() && 
                                                    item.id_parent == null)){
                            
                            this.$refs.form.setErrors({"category_name":[this.trans('settings-category-exist',208)]});
                            result = false;
                        }
                    }
                    
                    if (!result) {       
                        this.loading = false;    
                        return false;
                    }

                    axios
                        .post('settings/translations/categoryAdd', {
                            category_name: this.category_name,
                            type: this.cArgs.type,
                            item: JSON.stringify(this.cArgs.item),
                            elements: JSON.stringify(this.cArgs.elements),
                            filters: JSON.stringify(this.cArgs.filters)
                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.cArgs.filters.category = res.data.find(value => value.active == true).id
                            if(this.cArgs.type == 'sub'){
                                 this.cTitle += ' | ' + res.data.find(value => value.active == true).name
                            }else{
                                this.cTitle = res.data.find(value => value.active == true).name;
                            }
                           
                            this.$emit('update:show', false) 
                        })
                        .catch((error) => {
                            console.error(`error during request ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;   
                        })
                });
        },

        closeModal(){
            this.computedShow = false;
        },
    }

    
}
</script>
<style scoped>

</style>