<template>
    <b-modal size="lg" v-model="computedShow" no-close-on-backdrop :title="trans('settings-add-translation',167)">
        <ValidationObserver ref="form" >

            <app-row-left-label class="mt-2" :label="trans('settings-key-name',167)">
                    <app-input v-model="filters.search" validatorId="key_name" :loading="loading_search" size="sm" :timer="1000" @custom-lazy="lazy"  validatorName="Sleutelnaam" validatorRules="required" />
            </app-row-left-label>


            <b-card class="local-card">

                <app-view-table :loading="loading_search" :isTopBar="false" :isDropdownMenu="false" :filters="filters" :removeAll="false" :items="items" :fields="fields">
                
                </app-view-table>
            </b-card>

        
             <b-tabs ref="tabs" class="mb-3 mt-3" justified >
                  <b-tab title="NL">
                        <app-memo v-model="value_nl" rows="7" :maxLength="100000"/>
                  </b-tab>  
                  <b-tab title="EN">
                         <app-memo v-model="value_en" rows="7" :maxLength="100000" />
                  </b-tab>
             </b-tabs>


            <app-row-left-label  class="mt-2" :label="trans('description',182)">
                <app-memo v-model="description" rows="4" :maxLength="100000" />
            </app-row-left-label>

            <app-row-left-label  class="mt-2" label="Group">
                <app-input v-model="cArgs.category_name" :disabled="true"/>
            </app-row-left-label>

             <!-- <app-row-left-label  class="mt-2" label="Categorie">
                <app-select type="getShiftsRefApplicantsStatuses" v-model="category" validatorRules="required" validatorName="Categorie"/>
             </app-row-left-label> -->
        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="addTranslation" type="primary" :loading="loading">
                {{trans('add',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return"],

    mounted() {
        this.onCreateThis();
    },


    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         }
    },

    data(){
        return {
            loading: false,
            loading_search: false,
            search: '',
            lang_type:1,
            category: null,
            
            filters: {
                page: 1,
                per_page: 5,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                search: "", 
                
                status: 1,
                changed: 0,
            },

            last_search:"",
            app_type: this.args.filters.app_type,
            date_to: null,
            selected_id: null,
            value_en: '',
            value_nl: '',
            description: '',
            fields: [
                { key: "key_name", label: this.trans('settings-key-name',167), thStyle: { "min-width": "120px", "max-width": "120px"}, visible: true },
                { key: "value_nl", label: this.trans('settings-nl-value',167), thStyle: { "min-width": "120px", "max-width": "120px"}, visible: true },
                { key: "value_en", label: this.trans('settings-en-value',167), thStyle: { "min-width": "120px", "max-width": "120px"}, visible: true },
                { key: "category", label: this.trans('settings-group',167), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true },
                { key: "type", label: this.trans('type',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true },
                { key: "description", label: this.trans('description',182), thStyle: {  width: "100%"  }, visible: true },
            ],
            items: [],
            opt: [ 
                { value: 1, text: this.trans('settings-internal',167) }, 
                { value: 2, text: this.trans('settings-external',167) },
                { value: 3, text: this.trans('portal',182) },
            ],
        }
    },  

    methods: {

        async getTranslationsList(){
            
            
             if(this.filters.search == ''){
                    this.items = [];
                    this.selected_id = null;
            }else{
                this.loading_search = true;
                await axios
                    .post('settings/translations/getTranslationsList', {
                        filters: JSON.stringify(this.filters),
                    })
                    .then((res) => {
                        this.items = res.data.items;
                        this.last_search = this.filters.search;
                    })
                    .catch((error) => {
                        console.error(`error during request ${error}`);
                    })
                    .finally(() => {
                        this.loading_search = false;
                    })
            }
           
        },

        onClick(event){
            this.cArgs.elements.forEach(element => {
                if(element.id == event.id){
                    element.active = true;
                }else{
                    element.active = false;
                }
                
            });
        },

        async addTranslation(){
                this.loading = true;
                if(this.last_search != this.filters.search){
                    await this.getTranslationsList();
                }
                this.$refs.form.validate().then((result) => {
                    var same_name = false;
                    if(this.items.list != undefined && this.items.list.some(element => element.key_name.toUpperCase() == this.filters.search.toUpperCase() 
                                                                            && element.id_content_const_application == this.app_type
                                                                            && element.id_content_data_group == this.cArgs.category_id)){
                        this.$refs.form.setErrors({"key_name":["Sleutelnaam moet uniek zijn"]});
                        same_name = true;
                    }
                    
                    if (!result || same_name) {       
                        this.loading = false;    
                        return false;
                    }

                    
                    axios
                        .post('settings/translations/add', {
                            key_name: this.filters.search, 
                            value_nl: this.value_nl, 
                            value_en: this.value_en, 
                            description: this.description, 
                            id_content_data_group: this.cArgs.category_id, 
                            id_content_const_application:  this.app_type,
                            filters: JSON.stringify(this.args.filters),
                        })
                        .then((res) => {
                            this.cReturn = res.data.list.items;
                            this.translations.changeDictionary(res.data.dictionary);
                            this.$emit('update:show', false) 
                        })
                        .catch((error) => {
                            console.error(`error during request ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;   
                        })
                });
        },

        closeModal(){
            this.computedShow = false;
        },

        lazy(){

            if (!this.loading_list) {
                this.filters.page = 1;
                this.getTranslationsList();
            } 
        },

        onCreateThis() { 

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getTranslationsList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTranslationsList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTranslationsList();
                } 
            });

            this.$watch('filters.lang_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTranslationsList();
                } 
            });

            this.$watch('filters.app_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTranslationsList();
                } 
            });

            // this.$watch('filters.search', function(newVal, oldVal) {
            //     if (!this.loading_list) {
            //         this.filters.page = 1;
            //         this.getTranslationsList();
            //     } 
            // });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTranslationsList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getTranslationsList();
                }   
            });
        },
    }

    
}
</script>
<style scoped>


.local-card{
    min-height:250px !important; 
    max-height:600px !important
}


</style>