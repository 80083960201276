<template>
    <b-modal size="md" v-model="computedShow" no-close-on-backdrop :title="trans('settings-edit-group',167)">
        <ValidationObserver ref="form" >

           <app-row-left-label :label="trans('settings-group-name',207)">
                 <app-input v-model="cArgs.category_name" validatorName="Voornaam" validatorId="category_name" validatorRules="required" mask="lettersE5-40"/>
            </app-row-left-label>

        </ValidationObserver>

        <template #modal-footer>
            <app-button @click="closeModal" type="cancel">{{trans('cancel',175)}}</app-button>
            <app-button @click="editTranslation" type="primary" :loading="loading">
                 {{trans('save',175)}}
            </app-button>
        </template>

    </b-modal>
</template>
<script>

import axios from "@axios";
import { ValidationObserver } from "vee-validate";

export default {

    components: {
        ValidationObserver
    },

    props: ["result", "args", "show", "return"],

    watch: {
    },

    computed: {
        computedShow: {
            get() { return this.show },
            set(value) { this.$emit('update:show',value) }
        },

        cArgs: {
            get() { return this.args },
            set(value) { this.$emit('update:args', value) }
        },

         cReturn: {
             get() { return this.return },
             set(value) { this.$emit('update:return', value) }
         }
    },

    data(){
        return {
            loading: false,
        }
    },  

    methods: {


        async editTranslation(){
                this.loading = true;

                this.$refs.form.validate().then((result) => {
                    
                    if(this.cArgs.elements.some(item => item.name.toUpperCase() == this.cArgs.category_name.toUpperCase() && 
                                                item.id_parent == this.cArgs.elements.find(value => value.active == true).id_parent &&
                                                this.cArgs.category_name.toUpperCase() != this.cArgs.elements.find(value => value.active == true).name.toUpperCase() )){
                            
                            this.$refs.form.setErrors({"category_name":[this.trans('settings-category-exist',208)]});
                            result = false;
                    }

                    if (!result) {       
                        this.loading = false;    
                        return false;
                    }

                    axios
                        .post('settings/translations/categoryChange', {
                            id_content_data_group: this.cArgs.id_content_data_group,
                            category_name: this.cArgs.category_name,
                            elements: JSON.stringify(this.cArgs.elements),
                            filters: JSON.stringify(this.cArgs.filters)
                        })
                        .then((res) => {
                            this.cReturn = res.data;
                            this.cArgs.filters.category = res.data.find(value => value.active == true).id
                            this.$emit('update:show', false) 
                        })
                        .catch((error) => {
                            console.error(`error during request ${error}`);
                        })
                        .finally(() => {
                            this.loading = false;   
                        })
                });
        },

        closeModal(){
            this.computedShow = false;
        },
    }

    
}
</script>
<style scoped>

</style>