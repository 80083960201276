<template>
    <div>
        <b-row class="h-100 app-min-height">
            <b-col cols="3" class="h-100 app-min-height">   
                <b-card no-body class="h-100">
                    <b-card-body class="d-flex flex-column">
                        <b-row class="mb-4">
                            <b-col class="text-right" cols="6" >   
                                <b-button-group   size="sm">
                                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.mode !== 1, 'app-list-button-selected':  filters.mode === 1 }" @click="filters.mode = 1">{{trans('settings-group',167)}}</b-btn>
                                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.mode !== 2, 'app-list-button-selected':  filters.mode === 2 }" @click="filters.mode = 2">{{trans('settings-all',167)}}</b-btn>       
                                </b-button-group> 
                            </b-col>                           

                            <b-col class="app-input-employee-filter-width app-local-title-height pl-0 d-flex align-items-center" cols="6" end >
                                <app-select v-model="filters.app_type"  reduceValue :clearable="false"  :options="filters_app_list" class="flex-grow-1" />
                                <b-icon icon="download" v-if="programmersList.includes(user_id) && !loading_download" @click="downloadDictionaryJson" class="app-local-download ml-2"/>
                                <b-spinner v-if="loading_download" class="app-local-spinner" small></b-spinner>
                            </b-col>
                        </b-row>

                            <app-tree-list @click="onClick"  :key="refresh" class="app-height  overflow-auto pb-4"  :disabled="filters.mode != 1 || loading_list"  :elements="elements">
                                <template  v-slot:custom-icons>
                                </template> 
                                
                                <template v-slot:menu-items="data">
                                    <b-dropdown-item @click="editCategory(data.data)">{{trans('change',175)}}</b-dropdown-item> 
                                    <b-dropdown-item @click="addCategory(data.data)">{{trans('add',175)}}</b-dropdown-item> 
                                    <b-dropdown-item v-if="data.data != null && data.data.children == 0" @click="deleteCategory(data.data)">{{trans('remove',175)}}</b-dropdown-item>
                                </template> 
                            </app-tree-list>

                            <app-button type="primary" class="mt-auto" :disabled="filters.mode != 1 || loading_list" @click="addMainCategory()">{{trans('settings-add-main-category',206)}}</app-button>

                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="9" class="h-100 app-min-height">   
                <b-card class="app-min-height">
                    <app-view-table :title="filters.mode == 1 ? title : this.trans('settings-translations',167)" :loading="loading_list" :isTitleTotal="true" :topFiltersCount="2" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="status_options" :isSearch="true">

                        <template v-slot:filter-col-2 >
                                <b-button-group  size="sm">
                                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.lang_type !== 2, 'app-list-button-selected':  filters.lang_type === 2 }" @click="filters.lang_type = 2">NL</b-btn>
                                    <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.lang_type !== 1, 'app-list-button-selected':  filters.lang_type === 1 }" @click="filters.lang_type = 1">EN</b-btn>       
                                </b-button-group>                             
                        </template>

                        <template v-slot:menu-items="cell">
                            <b-dropdown-item @click="editTranslation(cell.data.item)">{{trans('change',175)}}</b-dropdown-item> 
                            <b-dropdown-item @click="deleteItem(cell.data.item.id_content_data_main)">{{trans('remove',175)}}</b-dropdown-item>
                        </template> 

                        <template v-slot:buttons>

                            <app-button type="primary" @click="addTranslation(actual_item)" :disabled="actual_item == null">{{trans('add',175)}}</app-button>
                            <app-button type="primary" @click="editCategory(actual_item)" :disabled="actual_item == null">{{trans('change',175)}}</app-button>
                            <app-button type="primary" @click="addCategory(actual_item)" :disabled="actual_item == null">{{trans('settings-add-category',206)}}</app-button>
                            <app-button type="primary" v-if="actual_item != null && actual_item.children == 0" @click="deleteCategory(actual_item)" :disabled="actual_item == null">{{trans('settings-delete-category',206)}}</app-button>
                        </template>

                    </app-view-table>
                </b-card> 
            </b-col>
        </b-row> 

        <b-row class="mt-4">
            <b-col>
                <setting-translations-database-list />
            </b-col>
        </b-row> 


        <setting-translations-add v-if="mTranslationAdd.show" :args.sync="mTranslationAdd.args" :result.sync="mTranslationAdd.result" :show.sync="mTranslationAdd.show" :return.sync="items" />
        <setting-translations-edit v-if="mTranslationEdit.show" :args.sync="mTranslationEdit.args" :result.sync="mTranslationEdit.result" :show.sync="mTranslationEdit.show" :return.sync="items" />
        <setting-translations-category-edit v-if="mTranslationCategoryEdit.show" :args.sync="mTranslationCategoryEdit.args" :result.sync="mTranslationCategoryEdit.result" :show.sync="mTranslationCategoryEdit.show" :return.sync="elements" />
        <setting-translations-category-add v-if="mTranslationCategoryAdd.show" :args.sync="mTranslationCategoryAdd.args" :result.sync="mTranslationCategoryAdd.result" :show.sync="mTranslationCategoryAdd.show" :title.sync="title" :return.sync="elements" />
    </div>
</template>
<script>

import axios from "@axios";
import SettingTranslationsAdd from './SettingTranslationsAdd.vue';
import SettingTranslationsEdit from './SettingTranslationsEdit.vue';
import SettingTranslationsCategoryEdit from './SettingTranslationsCategoryEdit.vue';
import SettingTranslationsCategoryAdd from './SettingTranslationsCategoryAdd.vue';
import SettingTranslationsDatabaseList from './SettingTranslationsDatabaseList.vue';
import AppAlerts from '@core/scripts/AppAlerts';
import { event } from 'd3';
import moment from 'moment';

export default {
    components: { 
      SettingTranslationsAdd,
      SettingTranslationsEdit,
      SettingTranslationsCategoryEdit,
      SettingTranslationsCategoryAdd,
      SettingTranslationsDatabaseList,
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                search: "",
                app_type:1,
                mode: 1,
                lang_type:2,
                status: 1,
                changed: 0,
                category: 0
            },
            refresh: 0,
            filters_app_list: [
                { value: 1, name: this.trans('settings-internal',167) },
                { value: 2, name: this.trans('settings-external',167) },
                { value: 3, name: this.trans('portal',182) },  
                { value: 4, name: 'Backend' },                
            ],
            elements:[],
            title: this.trans('settings-dictionary-title',207),

             alertClass: new AppAlerts(),

            mTranslationAdd: { args: { filters: null,category_name: null, category_id: null, elements: null}, result: 0, return: null, show: false },
            mTranslationEdit: { args: { id_content_data_main: null,
                                        key_name: null, 
                                        value_nl: null, 
                                        value_en: null,
                                        category_name: null,
                                        category_id: null,
                                        description: null,
                                        id_content_const_category: null, 
                                        id_content_const_application: null,
                                        app_type: null,
                                        filters: null}, 
                                result: 0, return: null, show: false },

             mTranslationCategoryEdit: { args: { id_content_data_group: null,
                                        category_name: null,
                                        elements: null,
                                        filters: null}, 
                                result: 0, return: null, show: false },

            mTranslationCategoryAdd: { args: {item: null, elements: null, type: null},  result: 0, return: null, show: false },

            status_options: [
                { value: 1, name: "Bijwerken" },
                { value: 2, name: "Update" },
            ],
            fields: [
                { key: "category_id", label: this.trans('settings-group-id',207), thStyle: { "min-width": "150px", "max-width": "150px"}, visible: true },
                { key: "key_name", label: this.trans('settings-key-name',167), thStyle: { "min-width": "200px", "max-width": "200px"}, visible: true },
                { key: "value", label: this.trans('settings-value',207), thStyle: { "min-width": "400px", "max-width": "400px"}, visible: true },
                { key: "category", label: this.trans('settings-group',167), thStyle: { "min-width": "210px", "max-width": "210px" }, visible: true },
                { key: "description", label: this.trans('description',182), thStyle: {  width: "100%"  }, visible: true },
            ],
            items: {
                list:[],
                total: 0,
                refresh: 0
            },
            actual_item: null,
            test: [],
            programmersList: [2, 4, 6, 147, 150, 151, 154, 155, 160, 334],
            user_id: JSON.parse(localStorage.getItem('user')).id,
            loading_download: false
        };
    },

    methods: {
        getList: function() {
            if(this.filters.category == null && this.filters.mode == 1) {
                this.items.list = [];
                this.items.total = 0;
                this.items.refresh= 0;
                return;
            }
            this.loading_list= true;
            
            
            axios
                .post("settings/translations/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        changeItem(item){
            this.actual_item = item
        },

        getTree: function() {
            this.loading_list= true;
            axios
                .post("settings/translations/getTree", {
                    app_type: this.filters.app_type
                })
                .then((res) => {
                    this.elements = res.data;
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        onClick(event){
            this.changeItem(event);
            this.elements.forEach(element => {
                if(element.id == event.id){
                    element.active = true;
                }else{
                    element.active = false;
                    element.open = false;
                }
                
            });
            
            if(this.actual_item.id_parent == null){
                this.actual_item.open = true;
            } else {
                this.elements.find(el => el.id == this.actual_item.id_parent).open = true;
            }
            
            this.filters.category = event.id;
        },

        changeTitle(element){
            var activeItem =  this.elements.find(value => value.active == true);
            
            this.title = this.getTitle(activeItem);
        },


        getTitle(item){
            if(item ==null){
                return '';
            }
            var title = item.name;
            var id = item.id_parent;

            while(id){
                var element = this.elements.find(value => value.id == id);
                id = element.id_parent; 
                title = element.name + " | " + title
                
            }
            return title;
        },

        editCategory(item){
            this.mTranslationCategoryEdit.args.filters = this.filters;

            this.mTranslationCategoryEdit.args.id_content_data_group = item.id;
            this.mTranslationCategoryEdit.args.category_name = item.name;
            this.mTranslationCategoryEdit.args.elements = this.elements;
            this.mTranslationCategoryEdit.args.filters = this.filters;
           
            this.mTranslationCategoryEdit.show = true;
        },

        addCategory(item){
            this.mTranslationCategoryAdd.args.filters = this.filters;
            this.mTranslationCategoryAdd.args.elements = this.elements;
            this.mTranslationCategoryAdd.args.item = item;
            this.mTranslationCategoryAdd.args.type = 'sub';
           
            this.mTranslationCategoryAdd.show = true;
        },

        addMainCategory(){
            this.mTranslationCategoryAdd.args.filters = this.filters;
            this.mTranslationCategoryAdd.args.elements = this.elements;
            this.mTranslationCategoryAdd.args.type = 'main';
            this.mTranslationCategoryAdd.show = true;
        },

        deleteCategory(item){

            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){
                    this.loading_list= true,
                    axios
                        .post("settings/translations/categoryDelete", {
                            id_content_data_group: item.id,
                            elements: JSON.stringify(this.elements),
                            filters: JSON.stringify(this.filters)
                        })
                        .then((res) => {

                            if(res.data == -1){
                                this.alertClass.openAlertWarning(this.trans('settings-cannot-delete-group',208));
                                return;
                            }
                            this.elements = res.data;
                            this.filters.category = res.data.find(value => value.active == true);
                        })
                        .catch((error) => {
                            console.error(`error : ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        deleteItem(id_content_data_main) {

            

            this.alertClass.openAlertConfirmation(this.trans('settings-continue-confirmation',167)).then(res => {
                if(res == true){
                    this.loading_list= true,
                    axios
                        .post("settings/translations/delete", {
                            id_content_data_main: id_content_data_main,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                            this.filters.page = res.data.list.page;
                            this.items = res.data.list.items;
                            this.translations.changeDictionary(res.data.dictionary);
                        })
                        .catch((error) => {
                            console.error(`error : ${error}`);
                        })
                        .finally(() => {
                            this.loading_list = false;
                        });
                }
            });
        },

        downloadDictionaryJson(){
            this.loading_download = true;
            var type = null;
            if(this.filters.app_type == 1){
                type = 'Internal';
            } else if(this.filters.app_type == 2){
                type = 'External';
            } else if(this.filters.app_type == 3){
                type = 'Portal';
            }
            axios
                .post("settings/translations/getDictionary", {
                    language: this.filters.lang_type,
                    app_type: type,
                },
                {
                    responseType: 'blob', 
                    crossDomain: true,
                })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data], {type : 'application/json'}));
                    const link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', 'dictionary_' + moment().format('DD-MM-YYYY')+'.json');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                 })
                .finally(() => {
                    this.loading_download = false;
                });

        },  

        addTranslation(item){
            this.mTranslationAdd.args.category_name = this.getTitle(item);
            this.mTranslationAdd.args.category_id = item.id;
            this.mTranslationAdd.args.filters = this.filters;
            this.mTranslationAdd.args.elements =  JSON.parse(JSON.stringify(this.elements));
            this.mTranslationAdd.show = true;
        },

        editTranslation(item){
            this.mTranslationEdit.args.filters = this.filters;

            this.mTranslationEdit.args.id_content_data_main = item.id_content_data_main;
            this.mTranslationEdit.args.key_name = item.key_name;
            this.mTranslationEdit.args.value_nl = item.value_nl;
            this.mTranslationEdit.args.value_en = item.value_en;
            this.mTranslationEdit.args.category_name = this.getTitle(this.elements.find(value => value.id == item.category_id));
            this.mTranslationEdit.args.category_id = item.category_id;
            this.mTranslationEdit.args.description = item.description != null ? item.description : '';
            this.mTranslationEdit.args.id_content_const_category = item.id_content_const_category;
            this.mTranslationEdit.args.id_content_const_application = item.id_content_const_application;
           
            this.mTranslationEdit.show = true;
            
        },

        onCreateThis() { 

            this.getTree();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.mode', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.category',function(newVal, oldVal) {
                this.changeTitle();
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.lang_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.app_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getTree();
                    this.getList();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
 
};
</script>


<style scoped>
    .app-min-height{
        min-height: 590px;
    }

    .app-height{
       height: 425px;
       margin-bottom: 30px;
    }


    .app-custom-icon{
        color:rgba(0, 0, 0, 0.5);
    }
    .app-custom-icon:hover{
        color:#174066;
        cursor: pointer;
    }
    .app-custom-plus{
        font-size: 16px
    }

    .app-local-download{
        margin-right: -10px;
    }

    .app-local-download:hover{
        cursor: pointer;
    }
    .app-local-spinner{
        width:19px; 
        height:19px; 
        margin-right: -10px;
        margin-left: 5px;
    }

</style>